.titleItem {
  margin: 32px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  svg {
    font-size: 30px;
  }
}

.content {
  margin-right: 4px;
}

.products {
  overflow-y: scroll;
  overflow-x: clip;
}

.footerContent {
  margin: 0px 8px 8px 8px;
  // width: 100%;
  // padding: 0;

  // .button {
  //   width: 100%;
  //   height: 100%;

  //   .buttonText {
  //     width: 100%;
  //     display: flex;
  //     justify-content: space-between;
  //     margin: 0 16px;
  //   }
  // }
}

.basketDialog {
  width: 100%;
  position: absolute;
  
}
