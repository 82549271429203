.order {
  overflow-x: clip;
  overflow-y: scroll;
  text-align: start;
  width: 100%;
  padding-right: 4px;
  
  .titleItem {
    margin: 32px 0 32px 0;
  }

  .totals {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .continue {
    width: fit-content;
  }

  .footerContent {
    width: 100%;
    padding: 0;
    margin-bottom: 8px;
  
    .button {
      width: 100%;
      height: 100%;

      .buttonText {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  
}
