.countButtons {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .countButton {
    width: 100px;
    height: 50px;
  }

  .countText {
    font-size: 4vh;
    font-weight: 600;
  }
}
