.emailEntry {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 0px;  

  .emailPart {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .emailText {
    width: 100%;
  }
}
