.basketProduct {
  text-align: start;
  width: 100%;

  .productImage {
    width: 25%;
    object-fit: cover;
    image-rendering: auto;
    text-align: left;

    img {
      height: 100%;
      max-height: 60px;
      object-fit: contain;
      width: 100%;        
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: none;    

    .name {
      width: 100%;
      text-align: left;
    }
    .quantity {
      width: 15%;
      text-align: right;
    }
    .amount {
      width: 30%;
      text-align: right;
    }
  }

  .buttons {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .actions {
      width: 35%;

      display: flex;
      justify-content: space-between;
    }
    .button {
      height: 50px;
      width: 50px;

      svg {
        font-size: 30px;
      }
    }

    .buttonCount {
      width: 50%;
    }
  }
}
