.header {
  display: flex;
  height: 100%;
  width: 100%;

  .areaCompany {
    width: -webkit-fill-available;
    margin: 1px 0;
  }
  .areaLanguage {
    flex-basis: content;
  }
  .areaBasket {
    padding: 0 16px;
  }
}
