.basketMenu {

  .basketIcon {
    width: 24px;
    height: 24px;

    .badge {
      width: 24px;
      height: 24px;
      margin-top: 9px;
    }

  }


  .iconMenu {
    position: absolute;
    top: 50px;
    left: auto;
    right: 8px;
    background-color: blue;

  }  

}