
.App {
  //text-align: center;
  position: fixed;  
  width: 100%;
  height: 100%;
  text-align: -webkit-center;
  height: 100vh;;
}
body {
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}    
