.shopList {
  text-align: start;
  height: 100%;
  display: flex;
  flex-direction: column;

  .shopCode {
  }

  .button {
    margin-top: 16px;
    height: 48px;
  }
}
