.salePathListProduct {
  margin-top: 2vh;
  text-align: start;
  display: flex;

  .productData {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1px;
      

    .headerText{
      text-align: start;
      width: 100%;
      overflow-wrap: break-word;
    }

    .productInfo {
      text-align: start;
      width: 100%;
      overflow-wrap: break-word;
      margin-top: 2vh;
    }

    .headerAction {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 2vh;
      margin-bottom: 1vh;
    }
  
    .price{
      height: 2vh;
    }

    .arrow {
      font-size: 24px;
    }
  }

  .productImage {
    width: 40%;
    object-fit: cover;
    image-rendering: auto;
    text-align: center;

    img {
      height: 100%;
      max-height: 16vh;
      object-fit: contain;
      width: 100%;        
    }
  }
}
