.order {
  text-align: start;
  padding-left: 5px;
  padding-right: 5px;
  overflow-x: clip;
  overflow-y: scroll;

  .header {
    text-align: left;

    .titleItem {
      margin: 32px 0 32px 0;
    }

  }

  .buttons {
  
    .button {
      width: 100%;
      height: 50px;
      text-transform: none;
      margin: 10px 0;
    }
  }
 
  .orderContent {
    margin-top: 5px;

    .totals {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
