.shop {
  padding-left: 8px;
  padding-top: 32px;
  text-align: -webkit-center;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  .title {
    margin-bottom: 16px;
  }
}
