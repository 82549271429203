.listItem {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  border-radius: 8px;

  .content {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .icon {
      padding-right: 12px;
    }

    .info {
      text-align: start;
    }
  
  }
  
  .action {
    text-align: center;
    margin-top: 6px;

    .arrow {
      font-size: 28px;
    }
  }
}
