@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  
  margin: 0;
  /* font-family: 'Montserrat', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  border-left-style: dotted;
  border-left-color: white;

}

body.keyboard-open {
  height: 100vh;
  overflow: hidden;
  border-left-style: dotted;
  border-left-color: red;
}

* {
  box-sizing: border-box;
  transition: background-color .3s ease-in-out;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: transparent;
  border: 0.4vw solid #9EA6B4;
  border-width: 1px;
}