.salePathProduct {
  height: 100%;
  width: 100%;

  text-align: start;

  .scrollable {
    height: 100%;
    overflow-x: hidden;
    overflow-y: overlay;
    padding-right: 4px;
    //padding-bottom: 4px;
  }

  .header {
    width: 100%;

    h2 {
      margin-top: 2vh;
    }
    h3 {
      margin-top: 3vh;
    }
  }

  .description {
    width: 100%;
    margin-top: 3vh;
    overflow-wrap: break-word;
  }

  .image {
    width: 100%;
    object-fit: cover;
    image-rendering: auto;
    text-align: center;

    img {
      height: 100%;
      max-height: 30vh;
      object-fit: contain;
      width: 80%;        
    }
  }

  .actions {
    width: 100%;

    .count {
      margin-top: 2vh;
    }
    .buttons {
      margin-top: 2vh;
    }
  }

  .properties {
    width: 100%;
    margin-top: 20px;

    .property {
      width: 100%;
    }
  }

  .children {
    width: 100%;

    .child {
      width: 100%;
    }
  }
}
