.allergens {
  text-align: start;
  margin-top: 2vh;

  .allergenList {
    text-align: start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 2vh;

    .allergen {
      text-align: start;
    }
  }
}
