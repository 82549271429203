.companyName {
  text-align: start;
  height: 100%;
  max-width: 60vw;
  overflow: hidden;

  img {
    height: 100%;
    max-width: 100%;
  
    object-fit: cover;
    image-rendering: auto;
  }
}