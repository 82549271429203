.footer {

  margin-top: 4px;

  .documents {
    display: flex;
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;   
  }

}
