.conceptsContainer {
  width: 100%;
  height: 100%;
  scroll-behavior: auto;
 
  .isLoading {
    font-size: 3vw;

    .wait {
      display: flex;
      justify-content: center;
    }
  }
  
  .concepts {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
