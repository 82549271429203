.deliveryTime {
  text-align: start;

  .deliveryTimeSelect {
    margin-left: 52px;
    width: -webkit-fill-available;
    max-height: 100px;
  }

  .time {
    width: auto;
    max-height: 160px;
    margin-top: 10px;
    margin-left: 30px;
    border-style: solid;
    border-radius: 10px;
    border-width: thin;
    border-width: 2px;
  }
}
