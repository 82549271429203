.addToCart {
  position: sticky;
  bottom: 8px;
  width: -webkit-fill-available;

  // position: absolute;
  // bottom: 8px;
  // left: 8px;
  // right: 24px;
  // width: -webkit-fill-available;

  .button {
    width: 100%;
    height: 50px;

    .buttonText {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 2vh;
      text-transform: none;
    }
  }
}
