.receiptStatus {
  height: 100%;
  text-align: start;

  .titleItem {
    margin: 10px 0 10px 0;
  }

  .list {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .footer {
    width: 100%;
    height: 50px;

    .button {
      width: 100%;
      height: 100%;

      .buttonText {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 2vh;
        margin-right: 2vh;
      }
    }
  }

}
