.loyaltyCardEntry {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 0px;  

  .loyaltyCardPart {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .loyaltyCardText {
    width: 100%;
  }
}
