.receipt {

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image {
    overflow-x: clip;
    overflow-y: scroll;

    img {
      object-fit: contain;
      width: 100%;
    }
    
  }
  
  .footer {
    width: 100%;
    height: 50px;

    .button {
      width: 100%;
      height: 100%;

      .buttonText {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 2vh;
        margin-right: 2vh;
      }
    }
  }
  
}
