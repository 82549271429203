.shop {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.shopInfo {
  text-align: start;
  width: 100%;
}

.shopAction {
  min-width: 30%;
  height: 100%;
  max-height: 50px;
}
