.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  
  .header {
    height: 50px;
    margin-top: 5px;
    text-align: left;
  }
  
  .mainContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;

    // border-style: dashed;
    // border-color: blueviolet;

    .content {
      margin-bottom: 4px;
      // overflow-x: hidden;
      // overflow-y: scroll;

      .concepts {
        padding-right: 4px;
        overflow-x: hidden;
        overflow-y: scroll;
        // border-style: dashed;
        // border-color: green;

        .conceptsHeader {
          display: flex;
          flex-direction: column;
    
          .headerItem {
            margin-top: 32px;
          }
        }
    
        .conceptsList {
          margin-top: 32px;
        }
      }

      .browse {

        .breadcrumbs {
          height: 50px;
        }
    
        // .list {
        //   border-style: dashed;
        //   border-color: green;
        // }
        .product {
          overflow-x: hidden;
          overflow-y: scroll;
          // border-style: dashed;
          // border-color: yellow;
        }
    
      }
        
    }
    .footerContent {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;      

      margin-left: -8px;

      .cartButton {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
  }
 
}

