.proceedToCheckout {

  .button {
    width: 100%;
    height: 50px;

    .buttonText {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 5px;
      text-transform: none;
    }
  }
}
