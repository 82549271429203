.item {
    width: 100%;
    justify-content: space-between;

    .label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
