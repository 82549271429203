.specialDiets {
  font-size: 1.3vw;
  margin-top: 2vh;

  .specialDietList {
    text-align: start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 2vw;
    margin-top: 2vh;

    .specialDiet {
      border: solid;
      border-width: 0.1vw;
      border-radius: 2.0vw;
      text-transform: uppercase;
      padding: 0.3vw 2vw;
    }
  }
}
