.dialog {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .title {
    text-align: center;
    svg {
      font-size: 8vw;
      align-self: center;
      margin-top: 7vw;
    }
  }

  .actions {
    width: 100%;

    .buttons {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      gap: 20px;
      width: 100%;
    }
  
  }
}
