.salePathListItems {
  text-align: start;
  height: 100%;
  display: flex;
  flex-direction: column;

  
  .container {
    margin-top: 16px;
    padding-right: 4px;
    height: 100%;


    overflow-x: hidden;
    overflow-y: overlay;

    .item {
      width: 100%;
    }
  }
}
