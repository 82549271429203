.deliveryMethod {
  text-align: start;
  width: 100%;

  // .choice {
  //   padding: 6px 0;
  // }
  
  .entry {
    margin-top: 10px;
    margin-left: 52px;
    width: -webkit-fill-available;
  }
}
