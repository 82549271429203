.dialog {

  overflow-x: hidden;
  overflow-y: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 30px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;

    .document {
      margin-right: 4px;
      overflow-x: hidden;
      overflow-y: overlay;
      height: -webkit-fill-available;
      //background-color: coral;
    }

    .footer {
      height: 50px;
      width: 100%;
      //background-color: rgb(170, 220, 200, 0.5);

      // .button {
      //   max-width: 100px;
      // }
    }
  }
}