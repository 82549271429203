.root {
  padding-left: 8px;
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
  .header {
    height: 50px;
    margin-top: 5px;
    text-align: left;
  }
  
  .mainContainer {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    text-align: start;

    padding-right: 4px;

    .titleItem {
      margin: 32px 0 0 0;
    }
  
    .button {
      height: 50px;
    }

    .front {
      overflow-x: hidden;
      overflow-y: scroll;
      padding-right: 4px;
    }

    // .browse {

    // }

    .breadcrumbs {
      margin-top: 16px;
      margin-bottom: 16px;
      height: 20px;
    }
    .conceptsHeader {
      display: flex;
      flex-direction: column;

      .headerItem {
        margin-top: 32px;
      }
    }
    .conceptsContainer {
      margin-top: 32px;
    }
  }
  
}

